import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form, Input, message } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { User } from "../types/User";
import { addNewUser, deleteUser, getUsers } from "../utils";

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    const getUserList = async () => {
      const users = await getUsers();
      setUsers(users);
    };

    getUserList();
  }, []);

  const showAddUserModal = () => {
    setIsModalVisible(true);
  };

  const handleAddUser = async (values: User) => {
    const isSuccess = await addNewUser(values.username, values.password);

    if (isSuccess) {
      setUsers([...users, values]);
      setIsModalVisible(false);
      form.resetFields();
      message.success(`User "${values.username}" added successfully!`);
    } else {
      message.error(`Failed to add user "${values.username}"!`);
    }
  };

  const handleDeleteUser = async (username: string) => {
    const isSuccess = await deleteUser(username);

    if (!isSuccess) {
      message.error(`Failed to remove user "${username}"!`);
      return;
    }

    setUsers(users.filter((user) => user.username !== username));
    message.success(`User "${username}" removed successfully!`);
  };

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: User) => (
        <Button
          type="primary"
          danger
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteUser(record.username)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div className="w-full p-4 ">
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showAddUserModal}
        style={{ marginBottom: 16 }}
      >
        Add User
      </Button>

      <Table columns={columns} dataSource={users} />

      <Modal
        title="Add New User"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleAddUser}>
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: "Please input the user name!" }]}
          >
            <Input placeholder="Enter username" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password "
            rules={[{ required: true, message: "Please input password" }]}
          >
            <Input placeholder="Enter email address" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add User
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserManagement;
