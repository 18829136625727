import { get } from "lodash";
import { User } from "../types/User";
import { FirestoreHelper } from "./firebase-helper";

const firebaseConfig = {
  apiKey: "AIzaSyDnqY80zals-0Hx7il1rHwb5udYniKHTo8",
  authDomain: "fe-support-tools.firebaseapp.com",
  projectId: "fe-support-tools",
  storageBucket: "fe-support-tools.appspot.com",
  messagingSenderId: "127806752671",
  appId: "1:127806752671:web:5ec7deca3afcbdd6a79faa",
};

const firestoreHelper = new FirestoreHelper(firebaseConfig);

export const signInFirebase = async (
  email: string,
  password: string,
  code: string,
  type: string
) => {
  try {
    const user = await firestoreHelper.readDocument("bot_codes", "client_key");
    return get(user, "code") === code;
  } catch (error) {
    console.error("Error signing in:", error);
    throw error;
  }
};

export const addNewUser = async (email: string, password: string) => {
  try {
    await firestoreHelper.writeDocument("tx_users", email, {
      username: email,
      password: password,
      type: "USER",
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const deleteUser = async (email: string) => {
  try {
    await firestoreHelper.deleteDocument("tx_users", email);
    return true;
  } catch (error) {
    return false;
  }
};

export const getUsers = async (): Promise<User[]> => {
  try {
    const users = await firestoreHelper.readCollection<User>("tx_users");

    return users;
  } catch (error) {
    return [];
  }
};
