import { initializeApp, FirebaseApp } from "firebase/app";
import {
  getFirestore,
  Firestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  WhereFilterOp,
  DocumentData,
  setDoc,
  deleteDoc,
} from "firebase/firestore";

export class FirestoreHelper {
  private db: Firestore;

  constructor(firebaseConfig: object) {
    const app: FirebaseApp = initializeApp(firebaseConfig);
    this.db = getFirestore(app);
  }

  /**
   * Reads a document from Firestore
   * @param collectionPath - The collection path
   * @param documentId - The document ID
   * @returns The document data or null if the document does not exist
   */
  async readDocument<T>(
    collectionPath: string,
    documentId: string
  ): Promise<T | null> {
    try {
      const docRef = doc(this.db, collectionPath, documentId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data() as T;
      } else {
        console.log(`Document not found: ${documentId}`);
        return null;
      }
    } catch (error) {
      console.error("Error reading document:", error);
      throw error;
    }
  }

  /**
   * Writes a document to Firestore
   * @param collectionPath - The collection path
   * @param documentId - The document ID
   * @param data - The data to write
   * @returns void
   */
  async writeDocument<T>(
    collectionPath: string,
    documentId: string,
    data: T
  ): Promise<void> {
    try {
      const docRef = doc(this.db, collectionPath, documentId);
      await setDoc(docRef, data as DocumentData);
      console.log(`Document written: ${documentId}`);
    } catch (error) {
      console.error("Error writing document:", error);
      throw error;
    }
  }

  /**
   * Reads documents from Firestore based on a filter (e.g., sign-in).
   * @param collectionPath - The collection path
   * @param filters - Array of filters to apply
   * @returns Array of documents that match the filter
   */
  async readDocumentByFilter<T>(
    collectionPath: string,
    filters: {
      field: string;
      operator: string;
      value: any;
    }[]
  ): Promise<T[]> {
    try {
      const colRef = collection(this.db, collectionPath);
      let q = query(colRef);

      filters.forEach((filter) => {
        q = query(
          q,
          where(filter.field, filter.operator as WhereFilterOp, filter.value)
        );
      });

      const querySnapshot = await getDocs(q);
      const results: T[] = [];
      querySnapshot.forEach((doc) => {
        results.push(doc.data() as T);
      });

      return results;
    } catch (error) {
      console.error("Error reading documents by filter:", error);
      throw error;
    }
  }

  /**
   * Deletes a document from Firestore
   * @param collectionPath - The collection path
   * @param documentId - The document ID
   * @returns void
   */
  async deleteDocument(
    collectionPath: string,
    documentId: string
  ): Promise<void> {
    try {
      const docRef = doc(this.db, collectionPath, documentId);
      await deleteDoc(docRef);
      console.info(`Document deleted: ${documentId}`);
    } catch (error) {
      console.error("Error deleting document:", error);
      throw error;
    }
  }

  /**
   * Reads an entire collection from Firestore
   * @param collectionPath - The collection path
   * @returns Array of all documents in the collection
   */
  async readCollection<T>(collectionPath: string): Promise<T[]> {
    try {
      const colRef = collection(this.db, collectionPath);
      const querySnapshot = await getDocs(colRef);

      const results: T[] = [];
      querySnapshot.forEach((doc) => {
        results.push({ id: doc.id, ...doc.data() } as T);
      });

      console.info(
        `Read ${results.length} documents from collection: ${collectionPath}`
      );
      return results;
    } catch (error) {
      console.error("Error reading collection:", error);
      throw error;
    }
  }
}
