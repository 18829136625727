import React, { useEffect, useState } from "react";

const GamePage: React.FC = () => {
  const [loading, setLoading] = useState(0);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    const startLoading = () => {
      const increment = 1; // Increment by 1
      const delay = 100; // Delay of 100ms

      interval = setInterval(() => {
        setLoading((prev) => {
          if (prev >= 100) {
            if (interval) clearInterval(interval);
            return 100;
          }
          return Math.min(prev + increment, 100); // Ensure it doesn't exceed 100
        });
      }, delay);
    };

    startLoading();

    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  if (loading === 100) {
    return (
      <div
        style={{
          backgroundColor: "black",
          color: "limegreen",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          fontFamily: "monospace",
        }}
      >
        <h1 style={{ fontSize: "2rem", marginBottom: "20px" }}>
          KẾT NỐI THÀNH CÔNG
        </h1>
        <p style={{ fontSize: "1.25rem", textAlign: "center" }}>
          Vui lòng kích hoạt phần mềm
        </p>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "black",
        color: "limegreen",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        fontFamily: "monospace",
      }}
    >
      <div
        style={{
          fontSize: "2rem",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        {loading < 100 ? `ĐANG KẾT NỐI... ${loading}%` : "KẾT NỐI THÀNH CÔNG"}
      </div>
      <div
        style={{
          width: "80%",
          maxWidth: "600px",
          height: "20px",
          backgroundColor: "#222",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: `${loading}%`,
            height: "100%",
            backgroundColor: "limegreen",
            transition: "width 0.1s linear",
          }}
        />
      </div>
      {/* {loading === 100 && (
        <div
          style={{
            marginTop: "20px",
            fontSize: "1.5rem",
            textAlign: "center",
            animation: "blink 1s infinite",
          }}
        >
          CONNECTION ESTABLISHED
        </div>
      )} */}
    </div>
  );
};

export default GamePage;
