import React, { useState } from "react";
import LoginModal from "../pages/LoginModal";

const games = Array.from({ length: 36 }, (_, i) => ({
  id: i + 2,
  name: require(`../assets/games/game_${i + 1}.jpg`),
  icon: "🎮",
}));

const HomePage: React.FC = () => {
  const [selectedGame, setSelectedGame] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (gameId: number) => {
    setSelectedGame(gameId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedGame(null);
  };

  return (
    <div className="bg-transparent min-h-screen flex flex-col items-center py-10 px-4">
      <h1 className="text-2xl font-bold mb-6 text-white py-8 text-center">
        HỆ THỐNG BOT GAME VIP
      </h1>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 w-full max-w-4xl p-4 rounded-xl bg-white">
        {games.map((game) => (
          <div
            key={game.id}
            className="cursor-pointer flex flex-col items-center justify-center rounded-lg shadow-md hover:shadow-lg transition-all aspect-square"
            onClick={() => openModal(game.id)}
          >
            <img
              src={game.name}
              alt="game"
              className="rounded-lg w-full h-full object-fill"
            />
          </div>
        ))}
      </div>

      {/* Login Modal */}
      {selectedGame !== null && (
        <LoginModal
          isOpen={isModalOpen}
          onClose={closeModal}
          gameId={selectedGame}
        />
      )}
    </div>
  );
};

export default HomePage;
