import React, { useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { signInFirebase } from "../utils";
import { message } from "antd";

Modal.setAppElement("#root"); // Required for accessibility

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  gameId: number;
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onClose, gameId }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    const isSuccess = await signInFirebase(username, password, code, "USER");

    if (isSuccess) {
      message.success("Đăng nhập thành công!");
      await sleep(1000); // Simulate network latency
      onClose(); // Close the modal
      navigate("/game"); // Redirect to the game page
    } else {
      setError("Mã kích hoạt không chính xác");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto mt-20 outline-none"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
    >
      <h2 className="text-2xl font-bold mb-4 text-center">Đăng nhập</h2>
      <input
        type="text"
        placeholder="Nhập tài khoản"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className="w-full p-2 mb-4 border border-gray-300 rounded"
      />
      <input
        type="password"
        placeholder="Nhập khẩu"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="w-full p-2 mb-4 border border-gray-300 rounded"
      />
      <input
        type="text"
        placeholder="Nhập mã kích hoạt"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        className="w-full p-2 mb-4 border border-gray-300 rounded"
      />

      {error && <p className="text-red-500 mb-4">{error}</p>}
      <div className="flex justify-end gap-4">
        <button
          onClick={onClose}
          className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
        >
          Huỷ
        </button>
        <button
          onClick={handleLogin}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Đăng nhập
        </button>
      </div>
    </Modal>
  );
};

export default LoginModal;
